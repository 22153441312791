// Data for carousel
const carouselSlidesData = [
  {
    image: "background__image1"
  }, 
  {
    image: "background__image2"
  }, 
  {
    image: "background__image3"
  }
];

export default carouselSlidesData;

